import React from 'react'
import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import { AssetsRegistrationMain } from './AssetsRegistrationMain'

const assetsRegistrationRoutes = [
  /* -------------------------------------------- */
  /*                ADVANCED FILTER               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/assets-registration/filter',
    },
    component: (
      <AdvancedFilter
        mode="assets-registration"
        app="fixed-assets"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/assets-registration',
    },
    component: <AssetsRegistrationMain />,
  },
]

export default assetsRegistrationRoutes
