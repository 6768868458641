import React from 'react'
import Paper from '@material-ui/core/Paper'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'

export const ROUALLSTable = (props: any) => {
  const { tableData } = props

  const dummyData = [
    {
      Year: '2023',
      ScheduledYear: '0',
      Value: 700000,
      Status: 'Approved',
    },
    {
      Year: '2023',
      ScheduledYear: '1',
      Value: 700000,
      Status: 'Generated',
    },
    {
      Year: '2023',
      ScheduledYear: '2',
      Value: 700000,
      Status: 'Pending',
    },
    {
      Year: '2023',
      ScheduledYear: '3',
      Value: 700000,
      Status: 'Pending',
    },
    {
      Year: '2023',
      ScheduledYear: '4',
      Value: 700000,
      Status: 'Pending',
    },
    {
      Year: '2023',
      ScheduledYear: '5',
      Value: 700000,
      Status: 'Pending',
    },
    {
      Year: '2023',
      ScheduledYear: '6',
      Value: 700000,
      Status: 'Pending',
    },
    {
      Year: '2023',
      ScheduledYear: '7',
      Value: 700000,
      Status: 'Pending',
    },
    {
      Year: '2023',
      ScheduledYear: '8',
      Value: 700000,
      Status: 'Pending',
    },
    {
      Year: '2023',
      ScheduledYear: '9',
      Value: 700000,
      Status: 'Pending',
    },
    {
      Year: '2023',
      ScheduledYear: '10',
      Value: 700000,
      Status: 'Pending',
    },
    {
      Year: '2023',
      ScheduledYear: '11',
      Value: 700000,
      Status: 'Pending',
    },
  ]

  // Please change accordingly once proper enum / data type has been prepared
  const coloredText = (status: string) => {
    if (status === 'Approved') {
      return 'green'
    } else if (status === 'Generated') {
      return 'darkblue'
    } else {
      return 'black'
    }
  }

  // Use Scheduled Year(Month) value as control for the time being; try to use index in the future
  const coloredCell = (scheduled: number) => {
    if (scheduled === 0 || scheduled % 2 === 0) {
      return '#FFFFFF'
    } else {
      return '#FFF1DE'
    }
  }

  return (
    <ContentWrapper multiDynamicInfo>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StickyTableCell>
                <StyledTableCell style={{ textAlign: 'center' }}>
                  Year
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: 'center' }}>
                  Scheduled
                  <br />
                  Year(Month)
                </StyledTableCell>
              </StickyTableCell>
              <StyledTableCell>Lease Liability b/f</StyledTableCell>
              <StyledTableCell>Lease Payment</StyledTableCell>
              <StyledTableCell>Principal</StyledTableCell>
              <StyledTableCell>Interest</StyledTableCell>
              <StyledTableCell>Lease Liability c/f</StyledTableCell>
              <StyledTableCell>Lease Liability -NBV</StyledTableCell>
              <StyledTableCell>Right of Use Asset</StyledTableCell>
              <StyledTableCell>Depreciation Charge</StyledTableCell>
              <StyledTableCell>Accumulated Depreciation</StyledTableCell>
              <StyledTableCell>NBV</StyledTableCell>
              <StyledTableCell style={{ textAlign: 'center' }}>
                Status
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyData?.map(x => {
              return (
                <StyledTableRow>
                  <StickyTableCell
                    style={{
                      backgroundColor: coloredCell(Number(x?.ScheduledYear)),
                    }}
                  >
                    <StyledTableCell
                      style={{
                        width: '5%',
                        fontWeight: 600,
                        textAlign: 'center',
                        color: coloredText(x?.Status),
                      }}
                    >
                      {x?.Year}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontWeight: 600,
                        textAlign: 'center',
                        color: coloredText(x?.Status),
                      }}
                    >
                      {x?.ScheduledYear}
                    </StyledTableCell>
                  </StickyTableCell>
                  <StyledTableCell
                    style={{
                      color: coloredText(x?.Status),
                    }}
                  >
                    {amtStr(x?.Value)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color: coloredText(x?.Status),
                    }}
                  >
                    {amtStr(x?.Value)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color: coloredText(x?.Status),
                    }}
                  >
                    {amtStr(x?.Value)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color: coloredText(x?.Status),
                    }}
                  >
                    {amtStr(x?.Value)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color: coloredText(x?.Status),
                    }}
                  >
                    {amtStr(x?.Value)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color: coloredText(x?.Status),
                    }}
                  >
                    {amtStr(x?.Value)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color: coloredText(x?.Status),
                    }}
                  >
                    {amtStr(x?.Value)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color: coloredText(x?.Status),
                    }}
                  >
                    {amtStr(x?.Value)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color: coloredText(x?.Status),
                    }}
                  >
                    {amtStr(x?.Value)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color: coloredText(x?.Status),
                    }}
                  >
                    {amtStr(x?.Value)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: 'center',
                      color: coloredText(x?.Status),
                    }}
                  >
                    {x?.Status}
                  </StyledTableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </ContentWrapper>
  )
}

const StickyTableCell = withStyles(theme => ({
  head: {
    minWidth: '50px',
    left: 0,
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 1,
    backgroundColor: '#FF9800',
    color: theme.palette.common.white,
    borderBottom: 'none',
  },
  body: {
    minWidth: '50px',
    left: 0,
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 1,
    borderBottom: 'none',
    backgroundColor: '#FFFFFF',
  },
}))(TableCell)

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#FF9800',
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: 12,
    borderBottom: 'none',
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
  body: {
    color: theme.palette.common.black,
    fontSize: 12,
    borderBottom: 'none',
    textAlign: 'right',
  },
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#FFF1DE',
    },
  },
}))(TableRow)
