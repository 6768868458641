import React, { lazy } from 'react'
import assetsRegistrationRoutes from './AssetsRegistration/AssetsRegistrationRoutes'

const FixedAssetsSubmenu = lazy(() =>
  import('./FixedAssetsSubmenu').then(module => ({
    default: module.FixedAssetsSubmenu,
  }))
)

const fixedAssetsRoutes = [
  {
    props: { exact: true, path: '/fixed-assets/:CompanyID/' },
    component: <FixedAssetsSubmenu />,
  },
  ...assetsRegistrationRoutes,
]

export default fixedAssetsRoutes
