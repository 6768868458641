import PrintIcon from '@material-ui/icons/Visibility'
import { AttachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { useHistory, useParams } from 'react-router'
import './ARDetail.scss'
import React, { useState } from 'react'
import { AttachmentDialog } from 'components/Dialog/AttachmentDialog'
import { useDocumentListingQuery } from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { AttachFile } from '@material-ui/icons'
import { EInvoiceStatus } from '../EInvoice/EInvoiceStatusComponent'

interface DetailProps {
  listEl: any
  listStatus: string
  userList: any
  documentListing?: any
  mode?: string
  arSubmenu?: string
}

export const ARDetailContent = (props: DetailProps) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const {
    listEl,
    documentListing,
    listStatus,
    userList,
    mode,
    arSubmenu,
  } = props

  let primaryKey
  let subMenuName
  switch (arSubmenu) {
    case 'advance':
      primaryKey = 'AdvanceID'
      subMenuName = 'Advance'
      break
    case 'invoice':
      primaryKey = 'InvoiceID'
      subMenuName = 'Invoice'
      break
    case 'credit-note':
      primaryKey = 'CreditNoteID'
      subMenuName = 'CreditNote'
      break
    case 'debit-note':
      primaryKey = 'DebitNoteID'
      subMenuName = 'DebitNote'
      break
    case 'official-receipt':
      primaryKey = 'ReceiptID'
      subMenuName = 'Receipt'
      break
    case 'refund':
      primaryKey = 'RefundID'
      subMenuName = 'Refund'
      break
    default:
      break
  }

  const [openDoc, setOpenDoc] = useState<boolean>(false)
  const {
    loading: documentLoading,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: listEl?.[`${primaryKey}`],
    },
  })

  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu: resetVert,
  } = useMenuOption()

  return (
    <>
      {mode === 'detail' && (
        <>
          {arSubmenu === 'invoice' ? (
            <div className="content-wrap left">
              <div className="desc" style={{ fontWeight: 300 }}>
                Date
              </div>

              <div className="xsTitle" style={{ fontWeight: 500 }}>
                {formatDate(listEl?.DocDate)}
              </div>
            </div>
          ) : (
            <div className="content-wrap left">
              <div className="desc" style={{ fontWeight: 300 }}>
                Date
              </div>

              <div className="xsTitle" style={{ fontWeight: 500 }}>
                {formatDate(listEl?.DocDate)}
              </div>
            </div>
          )}
          <div className="content-wrap right" style={{ width: '30%' }}>
            <div className="desc" style={{ fontWeight: 300 }}>
              Transaction Date
            </div>

            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {formatDate(listEl?.TransactionDate)}
            </div>
          </div>

          <div style={{ float: 'right', width: '20%' }}>
            <div className="desc" style={{ fontWeight: 300 }}>
              {arSubmenu !== 'advance' && (
                <>
                  <span style={{ float: 'right', marginLeft: '10px' }}>
                    <span
                      onClick={() => {
                        history.push({
                          pathname: `/account-receivable/${CompanyID}/${arSubmenu}/${
                            listEl?.[`${primaryKey}`]
                          }/preview`,
                          state: {
                            ...listEl,
                            mode: 'detail',
                          },
                        })
                      }}
                    >
                      <PrintIcon
                        style={{
                          fontSize: 'medium',
                          verticalAlign: '-webkit-baseline-middle',
                        }}
                      />
                    </span>
                  </span>
                </>
              )}
              {!!listEl?.is_einvoice ? (
                <>
                  <span style={{ float: 'right', cursor: 'pointer' }}>
                    <EInvoiceStatus
                      einvoiceStatus={listEl?.einvoice_status}
                      data={listEl}
                    />
                  </span>
                </>
              ) : (
                <></>
              )}
              {documentListing && documentListing?.length > 0 && (
                <>
                  <span
                    style={{ float: 'right', color: '#000' }}
                    onClick={() => {
                      setOpenDoc(true)
                    }}
                  >
                    <AttachFile
                      className="mdDesc"
                      style={{
                        fontSize: 12,
                        color: 'orange',
                        paddingTop: '2px',
                      }}
                    />

                    <span
                      className="mdDesc"
                      style={{
                        float: 'right',
                        color: 'orange',
                        textDecoration: 'underline',
                        paddingRight: '5px',
                      }}
                    >
                      {documentListing?.length}
                    </span>
                  </span>
                </>
              )}
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ fontWeight: 300 }}>
              Reference No.
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {listEl?.RefNo}
            </div>
          </div>

          <div className="content-wrap right"></div>

          <div className="content-wrap full">
            <div className="desc" style={{ fontWeight: 300 }}>
              Debtor Name
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {listEl?.DebtorAccount?.DebtorName}
            </div>
          </div>

          {listEl?.PaymentMethod && (
            <>
              <div className="content-wrap right">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Bank Account No
                </div>
                <div
                  className="xsTitle"
                  style={{ fontWeight: 500, whiteSpace: 'break-spaces' }}
                >
                  {`${listEl?.BankAccount?.AccountNo} (${listEl?.BankAccount?.BankProfile?.Name})`}
                </div>
              </div>

              <div className="content-wrap full">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Payment Method
                </div>
                <div
                  className="xsTitle text-noflow"
                  style={{ fontWeight: 500 }}
                >
                  {listEl?.PaymentMethod?.Name}
                </div>
              </div>

              {listEl?.PaymentMethod?.Name?.includes('Card') && (
                <div className="content-wrap right">
                  <div className="desc" style={{ fontWeight: 300 }}>
                    Card Type
                  </div>
                  <div className="xsTitle" style={{ fontWeight: 500 }}>
                    {listEl?.CreditCardType?.Name}
                  </div>
                </div>
              )}

              {listEl?.PaymentMethod?.Name === 'Cheque' && (
                <>
                  <div className="content-wrap right">
                    <div className="desc" style={{ fontWeight: 300 }}>
                      Cheque No.
                    </div>
                    <div className="xsTitle" style={{ fontWeight: 500 }}>
                      {listEl?.ChequeNo}
                    </div>
                  </div>
                  <div className="content-wrap left">
                    <div className="desc" style={{ fontWeight: 300 }}>
                      Cheque Date
                    </div>
                    <div className="xsTitle" style={{ fontWeight: 500 }}>
                      {formatDate(listEl?.ChequeDate)}
                    </div>
                  </div>

                  <div className="content-wrap right">
                    <div className="desc" style={{ fontWeight: 300 }}>
                      Cheque Expiry Date
                    </div>
                    <div className="xsTitle" style={{ fontWeight: 500 }}>
                      {formatDate(listEl?.ChequeExpiryDate)}
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          <div className="content-wrap full">
            <div className="desc" style={{ fontWeight: 300 }}>
              Description
            </div>
            <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
              {listEl?.Description ?? listEl?.Remark}
            </div>
          </div>

          {listEl?.Remark && (
            <div className="content-wrap full">
              <div className="desc" style={{ fontWeight: 300 }}>
                Remark
              </div>
              <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
                {listEl?.Remark}
              </div>
            </div>
          )}

          {listEl?.TaxScheme?.Code && (
            <>
              <div className="content-wrap left">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Amount
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {amtStr(listEl?.Amount)}
                </div>
              </div>
              <div className="content-wrap right">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Tax
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {`
        ${listEl?.TaxScheme?.Code} 
            (${listEl?.TaxRate}%)
            `}
                </div>
              </div>

              <div className="content-wrap left">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Tax Amount
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {amtStr(listEl?.TaxAmt)}
                </div>
              </div>
              <div className="content-wrap right">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Total Amount
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {amtStr(listEl?.DocAmt)}
                </div>
              </div>
            </>
          )}

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.submittedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {
                userList?.filter(user => user.ID == listEl?.submittedBy)[0]
                  ?.name
              }
            </div>
          </div>
        </>
      )}

      {listStatus === 'COMPLETED' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.approvedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {userList?.filter(user => user.ID == listEl?.approvedBy)[0]?.name}
            </div>
          </div>
        </>
      )}

      <AttachmentDialog
        // title={subMenuName}
        menu={menu}
        openDoc={openDoc}
        setOpenDoc={setOpenDoc}
        AttachmentDetailView={AttachmentDetailView}
        DocumentListing={DocumentListing}
      />
    </>
  )
}
