import AnimatedFloatButton from '@ifca-root/react-component/src/components/Button/AnimatedFloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import {
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import { KeyboardArrowRight, MoreVert } from '@material-ui/icons'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { dateFormat } from 'helpers/StringNumberFunction/FormatDate'
import React, { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import AddIcon from '@material-ui/icons/Add'
import PublishIcon from '@material-ui/icons/Publish'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import InfiniteScroll from 'react-infinite-scroll-component'

export const LeaseContractEntryList = (props: any) => {
  const {
    loading,
    filteredList,
    getSearch,
    loadData,
    handleFilterDate,
    listStatus,
    transactionTypePrefix,
  } = props

  let history = useHistory()
  const { CompanyID }: any = useParams()
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()

  const GreyTooltip = withStyles({
    tooltip: { color: 'white', backgroundColor: '#9e9e9e' },
  })(Tooltip)

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()

  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any
  const [deleteDialog, setOpenDeleteDialog] = useState(false)
  const [docNoChecking, setDocNoChecking] = useState(true)

  const actions = [
    {
      icon: (
        <AddIcon
          onClick={() =>
            history.push(`/mfrs16/${CompanyID}/lease-contract/add`)
          }
        />
      ),
      name: 'Add',
    },
    {
      icon: <PublishIcon onClick={() => console.log('copy')} />,
      name: 'Import',
    },
  ]

  let showMoreVert: boolean, primaryKey

  switch (transactionTypePrefix) {
    case 'lease-contract':
      primaryKey = 'LeaseContractID'
      switch (listStatus) {
        case 'ACTIVE':
        case 'REJECTED':
          showMoreVert = true
          break
        default:
          showMoreVert = false
          break
      }
      break
    case 'rou-assets-lease-liability-schedule':
      primaryKey = 'LeaseContractID'
      break
    default:
      showMoreVert = false
      break
  }

  return (
    <>
      {/* {mutationLoading && <Loading />}
      {docNumHeaderLoading && <Loading />} */}
      <ContentWrapper float>
        <List className="core-list">
          {advancedFilterList(filteredList) === undefined ||
          advancedFilterList(filteredList)?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <InfiniteScroll
              dataLength={filteredList?.length}
              next={() => {
                loadData({
                  variables: {
                    CompanyID: CompanyID,
                    skip: filteredList?.length,
                    take: 30,
                    searchValue:
                      getSearch?.name === '' || !getSearch?.name
                        ? undefined
                        : getSearch?.name,
                  },
                })
              }}
              hasMore={true}
              className=""
              style={{ padding: '8px 6px' }}
              loader={
                loading && (
                  <div style={{ textAlign: 'center' }}>
                    {' '}
                    <CircularProgress />{' '}
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>-</b>
                </p>
              }
            >
              {advancedFilterList(filteredList)
                // ?.filter(handleFilterDate)
                ?.map((el, index) => {
                  return (
                    <ListItem
                      key={index}
                      style={{
                        paddingBottom: '25px',
                      }}
                    >
                      <ListItemText
                        primary={
                          <>
                            <span className={`mdLabel flex-space`}>
                              {el?.ContractNo}
                            </span>
                            <IconText
                              font="highlight-text xxTitle"
                              parentStyle={{ color: '#ff9800' }}
                              children={amtStr(el?.LeasePayment)}
                            />
                          </>
                        }
                        secondary={
                          <Grid
                            container
                            className="desc flex-space text-noflow"
                          >
                            <Grid
                              spacing={1}
                              container
                              className="table-content"
                            >
                              <Grid item xs={8}>
                                <span
                                  className={'flex-space'}
                                  style={{ fontSize: '10px' }}
                                >
                                  {`${dateFormat(el?.StartDate)} - ${dateFormat(
                                    el?.EndDate
                                  )} ${el?.LeaseMonth} Month`}
                                </span>
                              </Grid>
                              <Grid item xs={4}>
                                <IconText
                                  font="highlight-text xxTitle"
                                  parentStyle={{
                                    color: '#ff9800',
                                    justifyContent: 'end',
                                  }}
                                  children={amtStr(el?.TotalLeaseAmount)}
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              spacing={1}
                              container
                              className="table-content"
                            >
                              <Grid item xs={12}>
                                <div className="icon-text">
                                  <span className="desc text-noflow">
                                    {el?.Description}
                                  </span>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                      />
                      <ListItemSecondaryAction>
                        {showMoreVert === true ? (
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            aria-controls="menu-list"
                            aria-haspopup="true"
                            onClick={e =>
                              handleClick(e, el?.[primaryKey], index, el)
                            }
                            // style={
                            //   listStatus === 'ACTIVE' || listStatus === 'REJECTED'
                            //     ? {}
                            //     : { verticalAlign: '30px' }
                            // }
                          >
                            <MoreVert />
                          </IconButton>
                        ) : listStatus === 'SUBMIT' ? (
                          <IconButton edge="end" aria-label="arrow">
                            <KeyboardArrowRight
                              onClick={() =>
                                history.push({
                                  pathname: `/mfrs16/${CompanyID}/${transactionTypePrefix}/${el?.[primaryKey]}/post`,
                                  state: el,
                                })
                              }
                            />
                          </IconButton>
                        ) : (
                          <IconButton edge="end" aria-label="arrow">
                            <KeyboardArrowRight
                              onClick={() =>
                                history.push({
                                  pathname: `/mfrs16/${CompanyID}/${transactionTypePrefix}/${el?.[primaryKey]}/detail`,
                                  state: el,
                                })
                              }
                            />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
            </InfiniteScroll>
          )}
        </List>
        {listStatus === 'ACTIVE' && <AnimatedFloatButton actions={actions} />}
      </ContentWrapper>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {listStatus === 'ACTIVE' ? (
          <>
            <MenuItem
              // disabled={
              //   !CompanyID
              //     ? true
              //     : handlePermDisabled({
              //         permEnum: updatePermissionCheck,
              //       })
              // }
              onClick={() => {
                history.push({
                  pathname: `/mfrs16/${CompanyID}/${transactionTypePrefix}/${menu?.ID}/edit`,
                  state: menu?.obj,
                })
              }}
            >
              <span className="">Edit</span>
            </MenuItem>
            <MenuItem
              // disabled={handlePermDisabled({
              //   permEnum: deletePermissionCheck,
              // })}
              onClick={() => {
                setOpenDeleteDialog(true)
              }}
            >
              <span className="">Delete</span>
            </MenuItem>
          </>
        ) : (
          <MenuItem
            // disabled={
            //   !CompanyID
            //     ? true
            //     : handlePermDisabled({
            //         permEnum: updatePermissionCheck,
            //       })
            // }
            onClick={() => {
              history.push({
                pathname: `/mfrs16/${CompanyID}/${transactionTypePrefix}/${menu?.ID}/edit`,
                state: { ...menu?.obj, mode: 'resubmit' },
              })
            }}
          >
            <span className="">Resubmit</span>
          </MenuItem>
        )}
      </Menu>

      {/* <DeleteDialog
        // ID={ref}
        openDeleteDialog={deleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        title={'testing'}
        menu={menu?.obj}
        handleDelete={''}
      /> */}
    </>
  )
}
