import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { dateFormat } from '@ifca-root/react-component/src/helpers/StringNumberFunction/formatDate'
import { useGetLeaseContractQuery } from 'generated/graphql'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { useHistory, useParams } from 'react-router'
import { ROUALLSTable } from './ROUALLSTable/ROUALLSTable'
import React, { useState } from 'react'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'

export const ROUAssetsLeaseLiabilityScheduleDetail = (props: any) => {
  //=====================================
  //==============VARIABLES==============
  //=====================================
  let history = useHistory()
  const { CompanyID, LeaseContractID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const routeSegments = [
    { name: 'MFRS 16' },
    { name: 'ROU Assets & Lease Liability Schedule', current: true },
  ]
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [contractNo, setContractNo] = useState('')
  const [leasePayment, setLeasePayment] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [leaseMonth, setLeaseMonth] = useState(0)
  const [totalLeaseAmount, setTotalLeaseAmount] = useState('')

  //=====================================
  //================QUERY================
  //=====================================
  const {
    loading: LeaseContractLoading,
    data: { getLeaseContract } = { getLeaseContract: [] },
  } = useGetLeaseContractQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID, LeaseContractID },
    onCompleted: ({ getLeaseContract }) => {
      setContractNo(getLeaseContract[0]?.ContractNo)
      setLeasePayment(amtStr(getLeaseContract[0]?.LeasePayment))
      setStartDate(dateFormat(getLeaseContract[0]?.StartDate))
      setEndDate(dateFormat(getLeaseContract[0]?.EndDate))
      setLeaseMonth(getLeaseContract[0]?.LeaseMonth)
      setTotalLeaseAmount(amtStr(getLeaseContract[0]?.TotalLeaseAmount))
    },
  })

  //=====================================
  //===============FOOTER================
  //=====================================
  const footer: any[] = [
    {
      displayText: 'Close',
      props: {
        onClick: () => {
          setOpenDialog(false)
        },
        variant: 'contained',
        color: 'primary',
        type: 'submit',
      },
    },
  ]

  //=====================================
  //==============FUNCTION===============
  //=====================================
  const dialogBody = () => {
    return (
      <CardContents>
        <div className="content-wrap left">
          <div className="desc" style={{ fontWeight: 300 }}>
            Monthly Lease Payment
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {leasePayment}
          </div>
        </div>
        <div className="content-wrap right">
          <div className="desc" style={{ fontWeight: 300 }}>
            Total Lease Payment
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {totalLeaseAmount}
          </div>
        </div>
        <div className="content-wrap left">
          <div className="desc" style={{ fontWeight: 300 }}>
            Discounted Total Cash Flows
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {amtStr(getLeaseContract[0]?.DiscountedTotalCashFlows)}
          </div>
        </div>
        <div className="content-wrap right">
          <div className="desc" style={{ fontWeight: 300 }}>
            Account ROU
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {!!getLeaseContract[0]?.IsAccountROU ? 'Yes' : 'No'}
          </div>
        </div>
        <div className="content-wrap left">
          <div className="desc" style={{ fontWeight: 300 }}>
            Start Journal Date
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {startDate}
          </div>
        </div>
        <div className="content-wrap right">
          <div className="desc" style={{ fontWeight: 300 }}>
            Annual Discount Rate
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {amtStr(getLeaseContract[0]?.AnnualDiscountRate)}
          </div>
        </div>
        <div className="content-wrap right">
          <div className="desc" style={{ fontWeight: 300 }}>
            Description
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {getLeaseContract[0]?.Description}
          </div>
        </div>
      </CardContents>
    )
  }

  return (
    <>
      {LeaseContractLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(
            `/mfrs16/${CompanyID}/rou-assets-lease-liability-schedule`
          )
        }}
        smTitle={'MFRS 16'}
        title={user?.companyName}
        routeSegments={routeSegments}
        rightRouteSegments={[{ name: 'View', current: true }]}
      />
      <DynamicSubHeader
        title={
          <span
            className="click-text"
            onClick={() => {
              setOpenDialog(true)
            }}
          >
            {contractNo}
          </span>
        }
        rightText={leasePayment}
        infoLine={`${startDate} - ${endDate} ${leaseMonth} Month`}
        rightInfoLine={totalLeaseAmount}
      />
      <ROUALLSTable tableData={null} />
      <CommonDialog
        fullWidth={true}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
        }}
        sections={{
          header: {
            title: contractNo,
            infoLine: `${startDate} - ${endDate}`,
            rightInfoLine: `${leaseMonth} Month`,
          },
          body: dialogBody,
          footer: { actions: [...footer] },
        }}
      />
    </>
  )
}
