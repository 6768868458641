import React from 'react'
import advancesRoutes from './APAdvance/APAdvanceRoutes'
import { batchPaymentRoutes } from './APBatchPayment/APBatchPaymentRoutes'
import creditNoteRoute from './APCreditNote/APCreditNoteRoutes'
import debitNoteRoutes from './APDebitNote/APDebitNoteRoutes'
import apDigitalReportRoutes from './APDigitalReport/APDigitalReportRoutes'
import apGLExportRoutes from './APGLExport/APGLExportRoutes'
import invoiceRoutes from './APInvoice/APInvoiceRoutes'
import paymentRoutes from './APPayment/APPaymentRoutes'
import refundRoutes from './APRefund/APRefundRoutes'
import { APSubmenu } from './APSubmenu'
import creditorAccountRoutes from './CreditorAccount/CreditorAccountRoutes'
import selfBilledRoutes from './APSelfBilled/APSelfBilledRoutes'
import consolidateSelfBilledRoutes from './APConsolidateSelfBilled/ConsolidateSelfBilledRoutes'

const APRoutes = [
  {
    props: { exact: true, path: '/account-payable/:CompanyID' },
    component: <APSubmenu accountType="account-payable" />,
  },

  ...invoiceRoutes,
  // ...generalListingRoutes,
  ...advancesRoutes,
  ...creditNoteRoute,
  // ...GLDetailRoutes,
  ...creditorAccountRoutes,
  // ...creditNotePostingRoutes,
  ...debitNoteRoutes,
  ...selfBilledRoutes,
  // ...debtorRoutes,
  ...paymentRoutes,
  ...batchPaymentRoutes,
  ...refundRoutes,
  ...apGLExportRoutes,
  ...apDigitalReportRoutes,
  ...consolidateSelfBilledRoutes,
  // ...creditorProfileRoutes,
  // ...creditordebtorRoutes,
  // ...generalPostingListingRoutes,
]

export default APRoutes
