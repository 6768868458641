import { yupResolver } from '@hookform/resolvers'
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { MoreVert, Tune } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  GetDebtorTypeDocument,
  useCreateDebtorTypeMutation,
  useDeleteDebtorTypeMutation,
  useGetDebtorTypeLazyQuery,
  useGetEntityCoaQuery,
  useGetHomeSummaryQuery,
  useGetMasterCoaQuery,
  useUpdateDebtorTypeMutation,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/CustomHooks/useMenuOption'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory, useParams } from 'react-router'
import * as yup from 'yup'

export interface DebtorTypeProps {
  Name: string
  MasterCOAID: string
}

export const DebtorTypeListing = (props: any) => {
  const getSearch = localStorage?.getItem('searchFilter')
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const [debtorType, setDebtorType] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [deleteType, setDeleteType] = useState(false)
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const [TotalLength, setTotalLength] = useState(0)

  const [formMode, setFormMode] = useState('')

  const DebtorTypeSchema = yup.object().shape({
    Name: CommonYupValidation.requireField(SystemMsgs.name()),
    MasterCOAID: CommonYupValidation.requireField(SystemMsgs.code()),
  })
  const {
    handleSubmit,
    control,
    register,
    watch,
    setError,
    clearErrors,
    setValue,
    errors,
  } = useForm<DebtorTypeProps>({
    mode: 'onSubmit',
    resolver: yupResolver(DebtorTypeSchema),
  })
  //.........Query............//
  const {
    loading,
    called,
    data: {
      loggedInUserProfile,
      getAccountPermission,
      getCompany: curCompany,
      getRolePermission,
    } = {
      loggedInUserProfile: null,
      getAccountPermission: [],
      getCompany: [],
      getRolePermission: [],
    },
  } = useGetHomeSummaryQuery({
    fetchPolicy: 'network-only',
  })

  const [
    loadData,
    {
      fetchMore,
      loading: DebtorTypeLoading,
      error: DebtorTypeError,
      data: { getDebtorType, getDebtorTypeCount } = {
        getDebtorType: [],
        getDebtorTypeCount: null,
      },
    },
  ] = useGetDebtorTypeLazyQuery({
    fetchPolicy: 'network-only',

    onCompleted: ({ getDebtorType, getDebtorTypeCount }) => {
      if (getDebtorType?.length > 0) {
        setOriginalListing([...filteredList, ...getDebtorType])
      }
      if (!!getDebtorTypeCount) {
        setTotalLength(getDebtorTypeCount)
      }
    },
  })

  useEffect(() => {
    loadData({
      variables: {
        // CompanyID: CompanyID,
        take: 30,
        skip: 0,
      },
    })
  }, [])

  // useEffect(() => {
  //   if (
  //     (getDebtorType && getDebtorType?.length > 0) ||
  //     getDebtorType?.length === 0
  //   ) {
  //     // if (getDebtorType) {
  //     setOriginalListing(getDebtorType)
  //   }
  // }, [getDebtorType])

  const {
    loading: masterCOALoading,
    error: masterCOAError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaQuery({
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true },
  })

  //.........Mutation............//

  const [
    createDebtorType,
    { loading: createLoading, error: createError },
  ] = useCreateDebtorTypeMutation({
    onError: error => {},
    //fetchPolicy: 'network-only',
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      // setTimeout(() => {
      //   history.push({
      //     pathname: `/account-receivable/${CompanyID}/debtor-type`,
      //     state: { success: true, msgMode: 'create' },
      //   })
      // }, 500)
      setOriginalListing([])
      loadData({
        variables: {
          skip: 0,
          take: 30,
        },
      })
    },
  })

  const [
    updateDebtorType,
    { loading: updateLoading, error: updateError },
  ] = useUpdateDebtorTypeMutation({
    onError: error => {},
    //fetchPolicy: 'network-only',
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      // setTimeout(() => {
      //   history.push({
      //     pathname: `/account-receivable/${CompanyID}/debtor-type`,
      //     state: { success: true, msgMode: 'create' },
      //   })
      // }, 500)
      setOriginalListing([])
      loadData({
        variables: {
          skip: 0,
          take: 30,
        },
      })
    },
  })

  const [
    deleteDebtorType,
    { loading: deleteLoading, error: deleteDebtorError },
  ] = useDeleteDebtorTypeMutation({
    onError: error => {
      if (error.message.includes('FK_')) {
        handleClickDeleteErrorDebtorTypeDialog()
      }
    },
    //fetchPolicy: 'network-only',
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      // setTimeout(() => {
      //   history.push({
      //     pathname: `/account-receivable/${CompanyID}/debtor-type`,
      //     state: { success: true, msgMode: 'create' },
      //   })
      // }, 500)
      setOriginalListing([])
      loadData({
        variables: {
          skip: 0,
          take: 30,
        },
      })
    },
  })

  const handleClickDeleteErrorDebtorTypeDialog = () => {
    setDeleteError(true)
    handleClose()
  }

  const handleCOAChange = MasterCOAID => {
    setValue('MasterCOAID', MasterCOAID)
  }

  const [deleteError, setDeleteError] = useState(false)

  const onSubmit = data => {
    isEdit === false
      ? createDebtorType({
          variables: {
            input: {
              // CompanyID: CompanyID,
              MasterCOAID: data.MasterCOAID,
              Name: data.Name,
            },
          },
          refetchQueries: [
            {
              query: GetDebtorTypeDocument,
              variables: { skip: 0, take: 30 },
            },
          ],
        })
      : updateDebtorType({
          variables: {
            input: {
              DebtorTypeID: menu?.ID,
              MasterCOAID: data.MasterCOAID,
              Name: data.Name,
            },
          },
          refetchQueries: [
            {
              query: GetDebtorTypeDocument,
              variables: { skip: 0, take: 30 },
            },
          ],
        })

    setDebtorType(false)
    clearErrors()
  }

  const handleClickDeleteDebtor = () => {
    setDeleteType(true)
    handleClose()
  }

  const handleDelete = data => {
    deleteDebtorType({
      variables: {
        input: {
          DebtorTypeID: menu?.ID,
        },
      },
      refetchQueries: [
        {
          query: GetDebtorTypeDocument,
          variables: {
            CompanyID: CompanyID,
            skip: 0,
            take: 30,
          },
        },
      ],
    })
    setDeleteType(false)
  }

  /** This is for permission purposes */
  const { handlePermDisabled, handlePermHidden } = usePermissionChecker()
  /**ACL */

  //to make delay for search function for the query to load

  const [timer, setTimer] = useState(null)
  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }

    setTimer(
      setTimeout(() => {
        setOriginalListing([])
        loadData({
          variables: {
            //  CompanyID: CompanyID,
            skip: 0,
            take: 30,
            searchValue: change,
          },
        })
      }, 1000)
    )
  }

  return (
    <>
      {createLoading && <Loading />}
      {updateLoading && <Loading />}
      {deleteLoading && <Loading />}
      {loading && <Loading />}
      {DebtorTypeLoading && <Loading />}
      {masterCOALoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/account-receivable/general-setting`)} //<- back button action
        smTitle={'Account Receivable'}
        title={user?.accountName}
        routeSegments={[{ name: 'AR' }, { name: 'Debtor Type', current: true }]}
      />

      <div className="search-filter with-dropdown-filter ">
        <SearchHeader
          title="Debtor Type"
          value={`${TotalLength}`}
          // value={`${advancedFilterList(filteredList)?.length}`}
          search
          onChangeAction={e => {
            // handleSearch(e?.target?.value, ['MasterCOAID', 'Name'])
            changeDelay(e.target.value)
            localStorage.setItem('searchFilter', e.target.value)
          }}
          onCloseAction={() => {
            handleSearch('', [])
            localStorage.removeItem('searchFilter')
            setOriginalListing([])
            loadData({
              variables: {
                // CompanyID: CompanyID,
                skip: 0,
                take: 30,
              },
            })
          }}
          option={{
            icon: <Tune />,
            onClick: () => {},
            // history.push(
            // //   `/cash-book/${CompanyID}/submenu/${BankAccountID}/receipt/filter`
            // ),
          }}
        />
      </div>

      <ContentWrapper footer overflow>
        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <InfiniteScroll
              dataLength={filteredList?.length}
              next={() => {
                const currentLength = filteredList?.length
                loadData({
                  variables: {
                    // CompanyID: CompanyID,
                    skip: currentLength,
                    take: 30,
                    searchValue: getSearch === '' ? undefined : getSearch,
                  },
                })
              }}
              hasMore={true}
              className=""
              style={{ padding: '8px 6px' }}
              loader={
                DebtorTypeLoading && (
                  <div style={{ textAlign: 'center' }}>
                    {' '}
                    <CircularProgress />{' '}
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>-</b>
                </p>
              }
            >
              {filteredList?.map((el, index) => {
                return (
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <span className="xsTitle flex-space">{el.Name} </span>
                        </>
                      }
                      secondary={
                        <>
                          <span className="desc flex-space">
                            {el.MasterCOA?.Code}{' '}
                          </span>
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      {handlePermHidden({
                        permEnum:
                          AcctPermission.AccReceivableGeneralSettingsDebtorTypeDelete,
                      }) && (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={e => {
                            handleClick(
                              e,
                              el.DebtorTypeID,
                              index,
                              getDebtorType[index]
                            )
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
            </InfiniteScroll>
          )}
        </List>

        <Menu
          id="menu-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem
            onClick={() => {
              setDebtorType(true)
              setIsEdit(true)
              setFormMode('edit')
            }}
          >
            <span className="">Edit</span>
          </MenuItem>
          <MenuItem
            onClick={handleClickDeleteDebtor}
            disabled={
              getDebtorType?.find(
                x => x?.DebtorTypeID === menu?.obj?.DebtorTypeID
              )?.DebtorAccount?.length > 0
                ? true
                : false
            }
          >
            <span className="">Delete</span>
          </MenuItem>
        </Menu>
        <FloatButton
          // disabled={handlePermDisabled({
          //   permEnum:
          //     AcctPermission.AccReceivableGeneralSettingsDebtorTypeCreate,
          // })}
          onClick={() => {
            setDebtorType(true)
            setIsEdit(false)
          }}
        />
      </ContentWrapper>
      <Box mt={1}>
        <CommonDialog
          fullWidth={true}
          open={debtorType}
          onClose={() => setDebtorType(false)}
          sections={{
            header: {
              title: 'Debtor Type',
              rightText: isEdit ? 'Edit' : 'New',
              infoLine: menu?.obj?.Name,
            },
            body: () => (
              <>
                <div className="content-container">
                  <Controller
                    as={TextField}
                    name="Name"
                    required
                    label="Name"
                    control={control}
                    ref={register}
                    helperText={errors?.Name?.message}
                    error={errors?.Name ? true : false}
                    defaultValue={isEdit ? menu?.obj?.Name : ''}
                  />

                  <Controller
                    render={({ value, onChange }) => {
                      const defVal = getMasterCOA?.filter(
                        x => x?.MasterCOAID === menu?.obj?.MasterCOAID
                      )[0]
                      return (
                        <Autocomplete
                          options={getMasterCOA || []}
                          getOptionLabel={option =>
                            `${option?.Code} | ${option?.Name}`
                          }
                          fullWidth
                          onChange={(value, newValue: any) => {
                            handleCOAChange(newValue?.MasterCOAID)
                          }}
                          defaultValue={isEdit ? defVal : null}
                          renderOption={(props, option) => {
                            return (
                              <div>
                                <div>
                                  <span className="xsTitle">{props?.Code}</span>
                                </div>
                                <div className="desc">{props?.Name}</div>
                              </div>
                            )
                          }}
                          renderInput={(params: any) => {
                            return (
                              <div>
                                <TextField
                                  {...params}
                                  required
                                  label="GL Account Code"
                                  variant="standard"
                                  fullWidth
                                  value={value}
                                  helperText={errors?.MasterCOAID?.message}
                                  error={errors?.MasterCOAID ? true : false}
                                />
                              </div>
                            )
                          }}
                        />
                      )
                    }}
                    name="MasterCOAID"
                    autoComplete="off"
                    control={control}
                    multiline={true}
                    fullWidth
                    margin="dense"
                    ref={register}
                    required
                    defaultValue={
                      isEdit
                        ? getMasterCOA?.filter(
                            x => x?.MasterCOAID === menu?.obj?.MasterCOAID
                          )[0]?.MasterCOAID
                        : ''
                    }
                    helperText={errors?.MasterCOAID?.message}
                    error={errors?.MasterCOAID ? true : false}
                  />
                </div>
              </>
            ),
            footer: {
              actions: [
                {
                  displayText: 'Cancel',
                  props: {
                    onClick: () => setDebtorType(false),
                    variant: 'contained',
                    color: 'primary',
                  },
                },
                {
                  displayText: 'Confirm',
                  props: {
                    onClick: () => {
                      handleSubmit(onSubmit)()
                    },
                    variant: 'contained',
                    color: 'primary',
                    form: 'createUOM-form',
                    type: 'submit',
                  },
                },
              ],
            },
          }}
        />
      </Box>

      {/* /** DELETE DIALOG */}
      <CommonDialog
        fullWidth={true}
        open={deleteType}
        onClose={() => setDeleteType(false)}
        sections={{
          header: {
            title: 'Debtor Type',

            rightText: 'Delete',
            infoLine: menu?.obj?.Name,
          },
          body: () => (
            <div className="content-wrap full">
              <span className="mdDesc full">
                Are you sure you want to delete this Debtor Type?
              </span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setDeleteType(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => handleDelete(menu?.ID),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <CommonDialog
        fullWidth={true}
        open={deleteError}
        onClose={() => setDeleteError(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="xsTitle flex-space"
                        style={{ color: 'red' }}
                      >
                        Error!
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              <span className="text-noflow mdDesc">{`You are not allowed to delete this debtor type as it's already been used`}</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => {
                    setDeleteError(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  )
}
