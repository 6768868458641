import DateFnsUtils from '@date-io/date-fns'
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import PersonIcon from '@material-ui/icons/Person'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import AdvanceFilterCardExpansion from 'components/CardExpansion/AdvanceFilterCardExpansion'
import { GreyTooltip } from 'containers/ARAPModule/Components/Tooltip/CustomTooltip'
import {
  EInvoiceTrxType,
  useGetEinvoiceConsolidationLazyQuery,
  useGetHomeSummaryQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import {
  dateFormat,
  formatDashDate,
  formatDate,
  formatTime,
} from 'helpers/StringNumberFunction/FormatDate'
import {
  amtStr,
  replaceUnderscoresWithSpaces,
} from 'helpers/StringNumberFunction/NumFormatters'
import React, { Fragment, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory, useParams } from 'react-router'

export const ARConsolidateEInvoiceListing = (props: any) => {
  let history = useHistory()
  const today = new Date()
  const { CompanyID }: any = useParams()
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [selectedDocType, setSelectedDocType] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [isReset, setIsReset] = useState(false)
  const documentType = [
    EInvoiceTrxType.Invoice,
    EInvoiceTrxType.CreditNote,
    EInvoiceTrxType.DebitNote,
    EInvoiceTrxType.RefundNote,
    // { value: 'All', einvoice_type: 'ALL' },
  ]

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  const {
    register: registerFilter,
    watch: watchFilter,
    control: controlFilter,
    setValue: setValueFilter,
    getValues: getValueFilter,
  } = useForm()

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const {
    loading: HomeSummaryLoading,
    data: {
      loggedInUserProfile,
      getAccountPermission,
      getCompany: curCompany,
      getRolePermission,
    } = {
      loggedInUserProfile: null,
      getAccountPermission: [],
      getCompany: [],
      getRolePermission: [],
    },
  } = useGetHomeSummaryQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID,
    },
    onCompleted: () => {
      setOriginalListing([])
      setValueFilter('DocType', [])
      fetchConsolidateEinvoice({
        variables: {
          CompanyID: CompanyID,
          skip: 0,
          take: 30,
          searchValue: '',
        },
      })
    },
  })

  const [
    fetchConsolidateEinvoice,
    {
      loading: EinvoiceConsolidationLoading,
      data: { getEinvoiceConsolidation } = { getEinvoiceConsolidation: [] },
    },
  ] = useGetEinvoiceConsolidationLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getEinvoiceConsolidation }) => {
      if (!!isReset) {
        setIsReset(false)
        registerFilter('DocType')
        setValueFilter('DocType', [])
      }
      if (getEinvoiceConsolidation?.length > 0) {
        setOriginalListing([...filteredList, ...getEinvoiceConsolidation])
      }
    },
  })

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */
  const enumFormatter = (str: string) => {
    return ` ${str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(' ')}`
  }

  const onSubmitFilter = () => {
    setOriginalListing([])
    setSelectedDocType(a => [...getValueFilter('DocType')])
    setStartDate(
      getValueFilter('StartDate') !== null
        ? dateFormat(new Date(getValueFilter('StartDate')).toISOString())
        : ''
    )
    setEndDate(
      getValueFilter('StartDate') !== null
        ? dateFormat(new Date(getValueFilter('EndDate')).toISOString())
        : ''
    )
    fetchConsolidateEinvoice({
      variables: {
        CompanyID: CompanyID,
        skip: 0,
        take: 30,
        searchValue: getValueFilter('DocNo'),
        DocType: getValueFilter('DocType'),
        StartDate: getDateCondition('StartDate'),
        EndDate: getDateCondition('EndDate'),
      },
    })
  }

  const getDateCondition = date => {
    return getValueFilter(date) === null
      ? null
      : formatDashDate(new Date(getValueFilter(date)).toISOString())
  }

  const resetFilter = () => {
    setIsReset(true)
    setOriginalListing([])
    setSelectedDocType(x => [])
    setValueFilter('DocType', [])
    setValueFilter('DocNo', '')

    setValueFilter(
      'StartDate',
      new Date(today.getFullYear(), today.getMonth() - 1, 1)
    )
    setValueFilter(
      'EndDate',
      new Date(today.getFullYear(), today.getMonth(), 0)
    )
    setStartDate('')
    setEndDate('')

    fetchConsolidateEinvoice({
      variables: {
        CompanyID: CompanyID,
        skip: 0,
        take: 30,
        searchValue: '',
        // StartDate: formatDashDate(
        //   new Date(today.getFullYear(), today.getMonth() - 1, 1).toISOString()
        // ),
        // EndDate: formatDashDate(
        //   new Date(today.getFullYear(), today.getMonth(), 0).toISOString()
        // ),
      },
    })
  }

  const renderFilterHeader = () => {
    if ((startDate !== '' && endDate !== '') || selectedDocType?.length > 0) {
      return (
        <span className={`xsTitle flex-space`}>
          {selectedDocType?.length > 0 && 'Doc Type:'}
          {`${
            selectedDocType?.length > 0
              ? selectedDocType?.map(x => enumFormatter(x))?.toString()
              : ''
          }`}
          {startDate !== '' && endDate !== '' && selectedDocType?.length > 0
            ? `, `
            : null}
          {startDate !== '' && `From: ${startDate}`}
          {endDate !== '' && ` To: ${endDate}`}
        </span>
      )
    } else {
      return <span className={`xsTitle flex-space`}>Advance Search</span>
    }
  }

  return (
    <>
      {HomeSummaryLoading && <Loading />}
      {EinvoiceConsolidationLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/account-receivable/${CompanyID}`)
        }}
        smTitle={'Account Receivable'}
        title={curCompany[0]?.Name}
        routeSegments={[
          { name: 'AR' },
          { name: 'Consolidate e-Invoice', current: true },
        ]}
      />

      <ContentWrapper footer>
        <List className="core-list">
          <AdvanceFilterCardExpansion
            customColor={true}
            customColorCode="#faf2e8"
            expansion={expanded}
            summary={
              <ListItem
                style={{
                  backgroundColor: '#faf2e8',
                  color: '#ff9800',
                }}
              >
                <ListItemText primary={<>{renderFilterHeader()}</>} />
              </ListItem>
            }
          >
            <div
              style={{
                fontSize: '14px',
                marginBottom: '15px',
                display: 'block',
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  as={KeyboardDatePicker}
                  name="StartDate"
                  label="Doc Start Date"
                  control={controlFilter}
                  onChange={(date: Date | null) => {}}
                  format="dd/MM/yyyy"
                  value={watchFilter('StartDate')}
                  margin="normal"
                  allowKeyboardControl
                  ref={registerFilter}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  className="left"
                  defaultValue={
                    new Date(today.getFullYear(), today.getMonth() - 1, 1)
                  }
                  showTodayButton
                  fullWidth
                />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  as={KeyboardDatePicker}
                  name="EndDate"
                  label="Doc End Date"
                  control={controlFilter}
                  onChange={(date: Date | null) => {
                    setValueFilter('EndDate', date)
                  }}
                  format="dd/MM/yyyy"
                  value={watchFilter('EndDate')}
                  margin="normal"
                  allowKeyboardControl
                  ref={registerFilter}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  className="right"
                  defaultValue={
                    new Date(today.getFullYear(), today.getMonth(), 0)
                  }
                  showTodayButton
                  fullWidth
                />
              </MuiPickersUtilsProvider>

              {!isReset && (
                <Controller
                  render={({ value, onChange }) => {
                    return (
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        options={documentType || []}
                        getOptionLabel={option => `${enumFormatter(option)}`}
                        value={watchFilter('DocType')}
                        fullWidth
                        onChange={(value, newValue: any) => {
                          onChange(newValue)
                        }}
                        renderOption={(option, { selected }) => (
                          <Fragment>
                            <Checkbox
                              value={option}
                              checked={selected}
                              color="primary"
                            />
                            {`${enumFormatter(option)}`}
                          </Fragment>
                        )}
                        renderInput={(params: any) => {
                          return (
                            <div>
                              <TextField
                                {...params}
                                variant="outlined"
                                style={{ width: '100%' }}
                                label="Doc Type"
                                margin="normal"
                              />
                            </div>
                          )
                        }}
                      />
                    )
                  }}
                  value={watchFilter('DocType')}
                  name="DocType"
                  label="Doc Type"
                  margin="normal"
                  autoComplete="off"
                  fullWidth
                  multiline={true}
                  control={controlFilter}
                  select
                  ref={registerFilter}
                />
              )}

              <Controller
                as={TextField}
                style={{ marginTop: '8px', marginBottom: '12px' }}
                id="standard-basic"
                name="DocNo"
                label="Document No."
                autoComplete="off"
                control={controlFilter}
                fullWidth
                margin="dense"
                ref={registerFilter}
                defaultValue={''}
              />

              <Button
                type="reset"
                color="secondary"
                style={{
                  minWidth: '50px',
                  float: 'left',
                  marginBottom: '15px',
                }}
                variant="contained"
                onClick={() => {
                  resetFilter()
                }}
              >
                Reset
              </Button>

              <Button
                type="submit"
                color="primary"
                style={{
                  minWidth: '50px',
                  float: 'right',
                  marginBottom: '15px',
                }}
                variant="contained"
                onClick={() => {
                  onSubmitFilter()
                }}
              >
                Filter
              </Button>
            </div>
          </AdvanceFilterCardExpansion>

          <List className="core-list">
            {filteredList === undefined || filteredList?.length === 0 ? (
              <EmptyList
                title="No Record found"
                subtitle="Add a new record now."
              />
            ) : (
              <InfiniteScroll
                dataLength={filteredList?.length}
                next={() => {
                  const currentLength = filteredList?.length
                  fetchConsolidateEinvoice({
                    variables: {
                      CompanyID: CompanyID,
                      skip: currentLength,
                      take: 30,
                      searchValue: getValueFilter('DocNo') ?? null,
                      DocType: selectedDocType,
                      StartDate: getDateCondition('StartDate'),
                      EndDate: getDateCondition('EndDate'),
                    },
                  })
                }}
                hasMore={true}
                className=""
                style={{ padding: '8px 6px' }}
                loader={
                  HomeSummaryLoading && (
                    <div style={{ textAlign: 'center' }}>
                      <CircularProgress />
                    </div>
                  )
                }
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>-</b>
                  </p>
                }
              >
                {filteredList?.map(el => {
                  return (
                    <ListItem>
                      <ListItemText
                        primary={
                          <>
                            <span className="desc date-width">
                              {formatDate(el?.DocDate)}
                            </span>
                            <span className="flex-space mdLabel">
                              {el?.DocNo}
                            </span>
                            <span className="xsTitle c-orange">
                              {amtStr(el?.DocAmt)}
                            </span>
                          </>
                        }
                        secondary={
                          <>
                            <span className="desc" style={{ marginTop: '5px' }}>
                              {replaceUnderscoresWithSpaces(
                                el?.einvoice_trx_type
                              )}
                            </span>
                            <div
                              className="content-wrap full"
                              style={{ marginBottom: '-5px' }}
                            >
                              <Grid item xs={12}>
                                <div className="icon-text">
                                  <GreyTooltip
                                    disableFocusListener
                                    title={
                                      <Fragment>
                                        <Grid
                                          container
                                          wrap="nowrap"
                                          spacing={2}
                                        >
                                          <Grid item>
                                            <Avatar
                                              style={{
                                                width: '20px',
                                                height: '20px',
                                              }}
                                            >
                                              <PersonIcon
                                                style={{ fontSize: 'small' }}
                                              />
                                            </Avatar>
                                          </Grid>
                                          <Grid item xs zeroMinWidth>
                                            <div style={{ fontSize: '12px' }}>
                                              {el?.ProfileInfo?.name}
                                            </div>
                                            <div style={{ fontSize: '10px' }}>
                                              {`${formatDate(
                                                new Date()?.toISOString()
                                              )}
                                              ${formatTime(
                                                new Date().toISOString()
                                              )}`}
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </Fragment>
                                    }
                                    arrow
                                    enterTouchDelay={0}
                                  >
                                    <img
                                      src={SubmitterLog}
                                      style={{
                                        width: '12px',
                                        marginRight: '3px',
                                      }}
                                    />
                                  </GreyTooltip>
                                  <span className="desc text-noflow">
                                    {el?.Description}
                                  </span>
                                </div>
                              </Grid>
                            </div>
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete">
                          <KeyboardArrowRight
                            onClick={() => {
                              history.push({
                                pathname: `/account-receivable/${CompanyID}/consolidate-einvoice/${el?.ConsolidateEInvoiceID}`,
                                state: el,
                              })
                            }}
                          />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
              </InfiniteScroll>
            )}
          </List>
        </List>

        <FloatButton
          onClick={() =>
            history.push(
              `/account-receivable/${CompanyID}/consolidate-einvoice/add`
            )
          }
        />
      </ContentWrapper>
    </>
  )
}
