import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import {
  Grid,
  IconButton,
  useMediaQuery,
  List,
  ListItem,
  ListItemSecondaryAction,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React from 'react'

export const ARItemDetail = (props: any) => {
  const {
    CN = false,
    titleLabel,
    itemData,
    setOpenDialog,
    fetchBillItem,
    fetchCostCentre,
    resetMenu,
    setSelectedTax,
    setDetailMode,
    formMode,
    detailMode,
    getBillItem,
    getCostCentre,
    handleClick,
    keyItemID,
    isEinvoice,
    loadMasterCOA,
  } = props

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })
  return (
    <CardContents
      section={{
        header: {
          title: CN
            ? `${titleLabel} (${itemData?.length})`
            : `${titleLabel} Detail (${itemData?.length})`,
          onClickAction: () => {
            loadMasterCOA({
              variables: { IsLastNode: true, orderByAsc: 'Name' },
            })
            setOpenDialog(true)
            fetchBillItem()
            fetchCostCentre()
            resetMenu()
            setSelectedTax(null)
            setDetailMode('add')
          },
          icon: (
            <AddIcon
              htmlColor="white"
              fontSize="small"
              style={{
                width: '20px',
                height: '20px',
                margin: '0',
                background: theme.palette.primary.main,
                borderRadius: '3px',
                color: 'rgba(224,234,254,100)',
                marginTop: '10px',
                marginRight: '10px',
              }}
            />
          ),
        },
      }}
    >
      <div className="rm-padding table-wrap ">
        {itemData === undefined || itemData?.length === 0 ? (
          <EmptyList title="No Record Found" subtitle="Add New Record now." />
        ) : (
          itemData
            ?.sort((a, b) => {
              return a?.Sequence < b?.Sequence ? -1 : 1
            })
            ?.map((v, index) => {
              return (
                <>
                  <List
                    className="item-list content-wrap full text-noflow"
                    style={{ paddingTop: '0', paddingBottom: '0' }}
                  >
                    <ListItem>
                      <Grid spacing={1} container className="table-content">
                        <Grid item xs={1} style={{ placeSelf: 'start' }}>
                          <span className="xxTitle">{`${index + 1}.`}</span>
                        </Grid>

                        <Grid
                          item
                          xs={7}
                          style={{ placeSelf: 'start', marginTop: '8px' }}
                        >
                          <div
                            className="xxTitle text-noflow"
                            style={{ lineBreak: 'anywhere' }}
                          >
                            {formMode === 'add'
                              ? getBillItem?.find(
                                  x => x?.BillItemID === v?.BillItemID
                                )?.Name
                              : formMode === 'edit' && detailMode === 'add'
                              ? getBillItem?.find(
                                  x => x?.BillItemID === v?.BillItemID
                                )?.Name
                              : v?.Name ?? v?.BillItemName}
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={4}
                          style={{
                            placeSelf: 'start',
                            justifyContent: isDesktop ? 'center' : 'end',
                            marginTop: '8px',
                          }}
                        >
                          <div
                            className="xxTitle"
                            style={{ textAlign: 'right' }}
                          >
                            {amtStr(v?.DocAmt)}
                          </div>
                        </Grid>

                        <Grid item xs={1} style={{ placeSelf: 'start' }} />

                        {isEinvoice ? (
                          <Grid
                            item
                            xs={7}
                            style={{ placeSelf: 'start', marginTop: '-10px' }}
                          >
                            <div className="desc text-noflow">
                              {formMode === 'add'
                                ? `${
                                    getCostCentre?.find(
                                      x => x?.CostCentreID === v?.CostCentreID
                                    )?.Code
                                  } | ${v?.ClassificationCode}`
                                : `${v?.CostCentreCode ??
                                    v?.CostCentreName} | ${
                                    v?.ClassificationCode
                                  }`}{' '}
                            </div>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={7}
                            style={{ placeSelf: 'start', marginTop: '-10px' }}
                          >
                            <div className="desc text-noflow">
                              {formMode === 'add'
                                ? `${
                                    getCostCentre?.find(
                                      x => x?.CostCentreID === v?.CostCentreID
                                    )?.Code
                                  }`
                                : `${v?.CostCentreCode ?? v?.CostCentreName}`}
                            </div>
                          </Grid>
                        )}

                        <Grid
                          item
                          xs={4}
                          style={{ placeSelf: 'start', marginTop: '-10px' }}
                        >
                          <div className="desc" style={{ textAlign: 'right' }}>
                            {amtStr(v?.Amount) || amtStr(v?.BaseAmt)}
                          </div>
                        </Grid>

                        <Grid item xs={1} style={{ placeSelf: 'start' }} />

                        <Grid
                          item
                          xs={4}
                          style={{ placeSelf: 'start', marginTop: '-8px' }}
                        ></Grid>

                        <Grid
                          item
                          xs={isEinvoice ? 3 : 4}
                          style={{ placeSelf: 'start', marginTop: '-8px' }}
                        >
                          <div className="desc">
                            {`${v?.TaxCode} | ${parseInt(v?.TaxRate)}%`}
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={isEinvoice ? 4 : 4}
                          style={{ placeSelf: 'start', marginTop: '-8px' }}
                        >
                          <div className="desc" style={{ textAlign: 'right' }}>
                            {amtStr(v?.TaxAmt)}
                          </div>
                        </Grid>
                        <Grid item xs={1} style={{ placeSelf: 'start' }} />
                        <Grid
                          item
                          xs={11}
                          style={{ placeSelf: 'start', marginTop: '-8px' }}
                        >
                          <div className="desc text-noflow">{v?.Remark}</div>
                        </Grid>
                      </Grid>

                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          aria-controls="menu-list"
                          aria-haspopup="true"
                          onClick={e => {
                            handleClick(e, v[`${keyItemID}`], index, v)
                          }}
                          style={{ padding: '8px' }}
                        >
                          <MoreVert />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </>
              )
            })
        )}
      </div>
    </CardContents>
  )
}
